.drawer {
  height: 100%;
  position: absolute;
  left: 0;
  border-right: solid 1px #dbdbdb;
  transition: width 0.2s ease-out, margin-left 0.2s ease-out;
  background: #f5f5f5;
  z-index: 100;
}

.contentHiderWrapper {
  height: 100%;
  overflow: hidden;
}

.contentWrapper {
  padding: 22px;
  height: 100%;
}

.inline {
  position: relative;
}

.expandButton {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #008575;
  color: #008575;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  font-size: 1.4rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: calc(50% - 10px);
  z-index: 5;
}
