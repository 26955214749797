@import "../CollabFlowNode/CollabFlowNode.module.scss";

.header {
  width: $node-width;
  text-align: center;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 500;
  cursor: default;
}
