.icon {
  animation: rotate 3s infinite;
}

@keyframes rotate {
  16.66% {
    transform: rotate(60deg);
  }
  33.33% {
    transform: rotate(120deg);
  }
  50% {
    transform: rotate(180deg);
  }
  66.66% {
    transform: rotate(240deg);
  }
  83.33% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
