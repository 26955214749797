.searchBar {
  border: 1px solid #bdbdbd;
  font-size: 1.6rem;
  border-radius: 3px 0 0 3px;
  height: 40px !important;
  width: 245px !important;
  padding: 9px;
}

.searchButton {
  height: 40px;
  width: 86px;
  font-size: 1.4rem;
  font-weight: 500 !important;
  border-radius: 0 3px 3px 0 !important;
}

.searchInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.iconButton {
  position: absolute;
  right: 4px;
  top: 5px;
  height: 28px !important;
  color: #495057;
  padding: 0 7px;
}
