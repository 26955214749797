$cellHeight: 42px;
$tablePadding: 10px;

.cell {
  height: $cellHeight;
  padding-right: 8px;
}
.cellFirstRow,
.cellLastRow {
  height: $cellHeight + $tablePadding;
}

.cellFirstRow {
  padding-top: $tablePadding;
}

.cellLastRow {
  padding-bottom: $tablePadding;
}

.loading {
  padding: $tablePadding 0;
}

.headerCell {
  height: 40px;
  vertical-align: bottom;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
  padding-bottom: 7px;
}

.table {
  line-height: 1.6rem;
  width: 100%;
}

.tableContainer {
  max-height: 30rem;
  min-height: 30rem;
  overflow-y: auto;
}

.emptyTableMessage {
  padding: 2rem 0;
}

.filterSection {
  display: flex;
  justify-content: flex-end;
}

.filterInput {
  padding: 1rem 1.4rem;
  border: none;
  background-color: #8080801c;
  margin-bottom: 3rem;
  border-radius: 0.4rem;
}

.table_addInfo {
  margin: 2rem;
  text-align: right;
  font-size: 1.4rem;
}
