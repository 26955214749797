.tt_container {
  max-width: 20rem;
  font-size: 1.2rem;
  font-weight: 400;
}

.tt_heading {
  margin-bottom: 1rem;
  font-weight: 600;
}
