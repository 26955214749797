.table {
  table {
    margin-bottom: 0;
    background-color: white;
    tr {
      font-family: Roboto;
      font-size: 1.6rem;
      th {
        padding: 14px 20px;
        font-weight: 500;
      }
      td {
        padding: 14px 20px;
      }
    }
  }
}

.tableHeader {
  margin: 16px 24px;
}

.tableHeaderText {
  font-weight: 400;
  font-size: 2rem;
  margin-bottom: 16px;
}

.tableCard {
  border: 1px solid #bdbdbd;
}

.headerColumnText {
  margin-right: 8px;
}
