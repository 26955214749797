.Card_wrapper {
  color: #54575a;
  transition: 0.25s ease-out;

  &:hover {
    color: #54575a;
  }
}
.Card {
  display: flex;
  margin: 1rem;
  background-color: #ffffff;
  border-radius: 0.4rem;
  padding: 1.6rem;
  align-items: center;
  transition: 0.25s ease-out;
  overflow: hidden;
}

.card_elevation_0 {
  box-shadow: none;
}
.card_elevation_1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.card_elevation_2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.card_elevation_3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.card_elevation_4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
.card_elevation_5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
