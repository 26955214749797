.content {
}

.circle {
  width: 9px;
  height: 9px;
  margin-right: 6px;
  margin-bottom: 1px;
  border-radius: 50%;
  display: inline-block;
}

.yellow {
  background-color: #febe2d;
}

.green {
  background-color: #5cd89f;
}

.red {
  background-color: #ff0000;
}

.purple {
  background-color: #919cff;
}

.statusText {
  text-transform: capitalize;
}

.ownerTypeText {
  text-transform: capitalize;
  padding: 3px;
  border-radius: 5px;
}

.myOrganization {
  box-shadow: 0 0 0 2px #fdafbf;
  background-color: #fff2f5;
}

.member {
  box-shadow: 0 0 0 2px #53c8e7;
  background-color: #f0fcff;
}

.thirdParty {
  box-shadow: 0 0 0 2px #c4a6ff;
  background-color: #efedff;
}

.collaboration {
  box-shadow: 0 0 0 2px #ffeaa6;
  background-color: #fffded;
}

.modal {
  width: 280px !important;
  display: flex;
  flex-direction: column;
}

.cancelButton {
  margin-right: 8px;
}
