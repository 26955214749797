.collabOverview_metrics {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collabOverview_card {
  display: flex;
  width: 24rem;
  flex-direction: column;
  align-items: flex-start;
}

.metrics_number {
  font-size: 4rem;
  color: #008575;
  font-weight: 500;
}

.headers {
  display: flex;
  justify-content: space-between;
}

.collabName {
  font-size: 3rem;
}

.collabPaper {
  max-width: 90rem;
  width: 90rem;
}

.collab_info {
  display: flex;
  align-items: center;
}

.divider {
  margin: 0 2rem;
}

.peopleIcon {
  margin-right: 1rem;
  fill: #54575a;
}

.emailIcon {
  margin-left: 1rem;
  fill: #54575a;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.emailIcon:hover {
  fill: #008575;
}

.collab_section {
  margin: 4rem 0;
}

section h2 {
  font-size: 2rem;
}

.data_metrics {
  margin-top: 4rem;
  background-color: rgba(15, 186, 168, 0.1);
  border-radius: 0.4rem;
  padding: 2rem;
  display: flex;
  justify-content: space-evenly;
}

.metric {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  align-content: center;
}

.metric_title {
  font-weight: 600;
}

.metric_number {
  font-size: 3rem;
  color: #000000;
}

.collab_nodescription {
  color: #747576;
}

.linkButton {
  height: 40px;
  width: 120px;
  font-size: 14px;
}
