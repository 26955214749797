$node-width: 218px;

.container {
  width: $node-width;
  height: 28px;
  border-radius: 0 3px 3px 0;
  border: solid 0.5px #dbdbdb;
  background-color: #ffffff;
  padding-left: 8px;
  display: flex;
  align-items: center;
  border-left-width: 0;
  position: relative;
  z-index: 1000;
  cursor: pointer;
  color: #54575a;

  &:before {
    content: "";
    height: calc(100% + 2px);
    width: 5px;
    left: 0;
    position: absolute;
  }
}

.handle {
  cursor: default !important;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 500;
}

.highlighted {
  border-color: #54575a;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.thirdParty:before {
  background-color: #c4a6ff;
}

.member:before {
  background-color: #53c8e7;
}

.userOrg:before {
  background-color: #fdafbf;
}

.destination:before {
  background-color: #a1d2ab;
}

.collaboration:before {
  background-color: #f19e1f;
}
