.container {
  height: 100%;
  position: relative;
}

.dropdownButton {
  height: 32px;
  padding: 0 8px;
  background: #f5f5f5;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #54575a;
  line-height: 1.6rem;

  &Selected {
    color: #008575;
    font-weight: 700 !important;

    &:hover {
      color: #008575 !important;
    }
  }

  &:hover {
    background-color: rgba(15, 186, 168, 0.1);
    text-decoration: none;
    color: unset;
    font-weight: normal;
  }
}

.dropdownIndicator {
  margin-left: 8px;
}

.selectedBorder {
  height: 3px;
  position: absolute;
  bottom: 0;
  background: #008575;
}

.buttonPositioner {
  display: flex;
  align-items: center;
}

.dropdownMenu {
  position: absolute;
  top: calc(100% - 7px);
  border-radius: 3px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #dedede;
  background: #f5f5f5;
  display: none;
  z-index: 3;
  padding: 8px 3px;

  &Right {
    right: 0;
  }

  &Open {
    display: inline-block;
    max-height: 40rem;
    overflow: auto;
  }
}

.menuOption {
  display: block;
  color: unset;
  padding: 8px 5px;
  outline: none;
  white-space: nowrap;
  position: relative;
  line-height: 1.6rem;
  border: none;
  background: #f5f5f5;

  &Border {
    &:after {
      content: "";
      height: 1px;
      background-color: #d6d6d6;
      bottom: 0;
      position: absolute;
      width: calc(100% - 16px);
      left: 8px;
    }
  }

  &:hover {
    color: unset;
    text-decoration: none;
    font-weight: normal;
    background-color: #f2f2f2;
  }
}
