.roundedButton {
  display: flex;
  border: none;
  background: none;
  padding: 4px;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;

  &:hover {
    background: rgba(15, 186, 168, 0.1);
  }

  &Small {
    @extend .roundedButton;
    height: 32px;
    width: 32px;
  }
}

.navContainer {
  padding: 14px 24px 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.hamburger {
  margin-top: 7px;
  margin-right: 12px;
  height: 32px;
  width: 32px;
}

.navOptionRightSection {
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }
}

.tab {
  color: var(--bh-black);
  margin-right: 24px;
  &:hover {
    text-decoration: none;
    font-weight: 500;
  }
}

.activeTab {
  font-weight: 500;
  color: var(--blue);
  border-bottom: 2px solid var(--blue);
}
