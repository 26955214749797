.navContainer {
  padding: 3rem;
  display: flex;
  background-color: white;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.headers {
  display: flex;
  flex-direction: column;
}

.header_mainText {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 6px;
}

.header_helperText {
  font-size: 1.4rem;
}

.hamburger {
  margin-right: 12px;
}

.navSection {
  height: 100%;
  display: flex;
  align-items: center;
}

.navOption {
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }
}

.navOptionRightSection {
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }
}

.title {
  font-size: 2rem;
}

.roundedButton {
  display: flex;
  border: none;
  background: none;
  padding: 4px;
  border-radius: 45px;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;

  &:hover {
    background: rgba(15, 186, 168, 0.1);
  }

  &Small {
    @extend .roundedButton;
    height: 32px;
    width: 32px;
  }
}

.userInfo_navContainer {
  margin-right: 1rem;

  span {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 1.4rem;
  }

  .userName {
    font-weight: 600;
  }
}

.logo {
  width: 140px;
}

.betaText {
  margin-left: 8px;
  color: var(--blue);
  font-weight: 600;
  margin-top: 2px;
}

.bottomHeader {
  margin-left: 3px;
}

.dropdown {
  margin-top: 7px;
}

.expandIcon {
  margin-top: 9px;
}

.collabNameText {
  color: var(--blue);
  font-weight: 600;
  margin-right: 5px;
}