.positioner {
  position: relative;
  height: 100%;
  flex: 1;
}

.scrollContainer {
  position: absolute;
  inset: 0;
  overflow: auto;
}
