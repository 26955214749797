@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700&display=swap");

:root {
  --blue: #008575;
  --light-blue: #008575b3;
  --bh-black: #54575a;
}

#root {
  height: 100%;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.2px;
  height: 100%;
  font-size: 1.6rem;
  color: var(--bh-black);
}

h1 {
  font-size: 4.8rem;
  font-weight: 700;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
}

p,
table {
  font-size: 1.6rem;
}

.table {
  color: var(--bh-black);
}

th,
td {
  word-wrap: break-word;
}

//a {
//  color: var(--blue);
//}

small {
  font-size: 1.4rem;
}

//a:hover {
//  color: var(--blue);
//  font-weight: 600;
//}

.Toastify__toast-container {
  font-family: inherit;
  position: absolute;
  width: 96%;
  z-index: 90000 !important;
}

hr {
  margin: 16px 0;
}

/*******************************
The following styles override 
Bootstrap properties.
*******************************/
/* Container */

/* Modal */

.modal {
  display: flex !important;
  align-items: center;
  justify-content: center;
  .btn {
    font-size: 1.4rem;
    height: 40px;
    width: 85px;
  }
}

.modal-backdrop.show {
  opacity: 0;
}

.modal-body {
  padding: 0;
}

.modal-body h1 {
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.modal-body h2 {
  font-size: 1.6rem;
  font-weight: 400;
}

.modal-content {
  border-radius: 2px;
  border: solid 0.5px #dedede;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  padding: 1.6rem;
  max-height: 80vh;
  overflow: hidden auto;
}

.modal-header {
  border-bottom: none;
  /* Use display block to enable float-right on the image. */
  display: block;
  font-size: 2rem;
  margin-bottom: 8px;
  padding: 0;
}

.modal-body {
  font-size: 1.6rem;
  margin-bottom: 16px;
}

/* Use custom styles for larger screens. Use responsive Bootstrap flex for mobile. */
@media screen and (min-width: 600px) {
  .modal-size {
    min-width: 611px;
  }

  .modal-body .btn {
    min-width: 168px;
    padding: 0 30px;
    height: 45px;
  }

  .modal-body button.btn-hw-none {
    width: auto;
    height: auto;
  }
}

/* Form */
.form-control {
  border-radius: 0;
  font-size: 1.6rem;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--bh-black);
  margin-bottom: 3px;
  vertical-align: middle;
}

.form-control,
.react-select__control {
  border: solid 0.5px #b0b0b0 !important;
  height: 38px;
  border-radius: 3px;
}

.react-select__indicator {
  color: #b0b0b0 !important;
}

.react-select__indicator-separator {
  background-color: #b0b0b0 !important;
}

.react-select__placeholder,
.react-select__menu {
  font-size: 1.6rem;
}

.css-klallp-control {
  border-radius: 3px;
}

.input-description {
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: normal;
}

/* Buttons */
.btn {
  border-radius: 4px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.2px;
  font-stretch: normal;
  font-style: normal;
  box-sizing: border-box;
  white-space: nowrap;
  border: none;
}

.btn-primary {
  background-color: var(--blue);

  &:active {
    background-color: var(--blue) !important;
  }

  &:disabled {
    background-color: #bfbfbf !important;
    cursor: not-allowed;
    &:hover {
      box-shadow: none !important;
    }
  }

  &:hover {
    background-color: var(--blue);
  }
}

.btn-secondary {
  background-color: white;
  border: 2px solid var(--blue);
  color: var(--blue);

  &:hover,
  &:active {
    background-color: white !important;
    border: 2px solid var(--blue) !important;
    color: var(--blue) !important;
  }
}

.btn-primary:hover,
.btn-secondary:hover,
.nav-pills:hover .nav-link:hover {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25) !important;
}

.btn-outline-primary {
  color: var(--blue);
  border-color: var(--blue);
  &:hover,
  &:active {
    background-color: white !important;
    color: var(--blue) !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
  }
}

.btn-lg {
  height: 45px;
  font-size: 1.6rem;
  padding: 0 40px;
}

.btn-md {
  font-size: 1.6rem;
  height: 36px;
  padding: 0 15px;
}

.btn-link {
  display: block;
  padding: 0;
  border: none;
  color: inherit;
  font-size: 1.6rem;
}

.btn-link:hover {
  text-decoration: none;
  color: var(--blue);
}

.btn-link:focus {
  text-decoration: none;
}

.modal-show-btn-icon {
  font-size: 2.2rem;
}

/* Tabs and Nav */
.nav-pills .nav-link {
  border-radius: 0;
  border: 1px solid var(--blue);
  font-size: 1.6rem;
}

.nav-link {
  padding: 0.8rem 1.6rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--blue);
}

.card {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/*******************************
The following styles are custom 
– often component specific and
often Bootstrap-inspired "Atomic."
*******************************/
.pr-6 {
  padding-right: 6.8rem;
}

.mt-6 {
  margin-top: 6.8rem;
}

.mt-xl {
  margin-top: 19.3.2rem;
}

.mb-xs {
  margin-bottom: 0.64rem;
}

.mb-2-alt {
  margin-bottom: 1.6rem;
}

.mb-6 {
  margin-bottom: 6.8rem;
}

.h-12 {
  height: 12% !important;
}

.lh-1 {
  line-height: 1;
}

.bb-grey {
  border-bottom: 1px solid #dee2e6;
}

.bg-white {
  background-color: #fff;
}

.icon-muted {
  color: #acb5bd;
}

.sm-font {
  font-size: 1.6rem;
}

.xl-font {
  font-size: 3.2rem;
}

.error-message {
  font-size: 1.6rem;
  color: #ff0101;
}

.text-accent {
  color: #b8b9ba !important;
  font-weight: 700;
}

.text-blue {
  color: var(--blue);
}

.text-decoration-underline {
  text-decoration: underline;
}

.img-max {
  max-width: 400px;
  width: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 60px;
  line-height: 60px;
}

.p-header {
  /* Same as h2 */
  font-size: 2.4rem;
  height: 4rem;
}

.border-top-0 {
  border-top: 0;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* React BS Table / Pagination (temporary styles) */
.page-item.active .page-link,
.page-item .page-link {
  background-color: #fff;
  color: inherit;
}

.page-item.active .page-link {
  border-color: #acb5bd;
}

.react-bootstrap-table-pagination-total {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}

.react-bs-table-csv-btn {
  color: var(--blue);
  float: right;
}

.react-bs-table-csv-btn:hover {
  color: var(--light-blue);
}

/*******************************
Styles for the filters.
*******************************/
.datepicker-div {
  background-color: white;
  border-radius: 4px;
  margin-top: 8px;
  position: absolute;
  z-index: 2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}

.filter-btn {
  padding: 6px 8px 6px 8px;
  border-radius: 2px;
  border-width: 0;
  box-shadow: none;
  color: #495057 !important;
  opacity: 1 !important;
}

.filter-btn,
.filter-btn:hover,
.filter-btn:active,
.filter-btn:focus {
  /* Force override Bootstrap styles */
  background-color: #fff !important;
  border: 1px solid #b0b0b0 !important;
}

.blue-gradient {
  background: linear-gradient(#ffffff, #fff 28%, rgba(15, 186, 168, 0.2));
}

[class$="-option"]:hover {
  background-color: rgba(204, 204, 204, 0.3) !important;
}

/*******************************
Pseudo-classes for focus and active.

These styles make a distinction between 
a user who presses `tab` to navigate
and a user who clicks the page.
*******************************/
.user-is-tabbing {
  .btn:focus,
  .btn:active,
  button:active,
  button:focus,
  .datepicker-div input:focus,
  .datepicker-div input:active,
  .datepicker-div select:focus,
  .datepicker-div select:active,
  input.form-control:focus,
  input.form-control:active,
  a.nav-link-cls:focus,
  a.nav-link-cls:active,
  .page-link:active,
  .page-link:focus,
  th:active,
  th:focus,
  .outline:active,
  .outline:focus {
    outline: 1px solid var(--light-blue) !important;
    box-shadow: 2px 1px 3px var(--light-blue), -2px 1px 3px var(--light-blue),
      -2px -1px 3px var(--light-blue), 2px -1px 3px var(--light-blue) !important;
  }
}

.btn.border-0:focus,
.btn.border-0:active {
  border: 0 !important;
}

.datepicker-div button.rdrDay:focus,
.datepicker-div button.rdrDay:active {
  outline: 0px !important;
  box-shadow: none !important;
}

body:not(.user-is-tabbing) .btn:focus,
body:not(.user-is-tabbing) .btn:active,
body:not(.user-is-tabbing) button:active,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) .datepicker-div input:focus,
body:not(.user-is-tabbing) .datepicker-div input:active,
body:not(.user-is-tabbing) .datepicker-div select:focus,
body:not(.user-is-tabbing) .datepicker-div select:active,
body:not(.user-is-tabbing) input.form-control:focus,
body:not(.user-is-tabbing) input.form-control:active,
body:not(.user-is-tabbing) a.nav-link-cls:focus,
body:not(.user-is-tabbing) a.nav-link-cls:active,
body:not(.user-is-tabbing) .page-link:active,
body:not(.user-is-tabbing) .page-link:focus,
body:not(.user-is-tabbing) th:active,
body:not(.user-is-tabbing) th:focus,
body:not(.user-is-tabbing) .outline:active,
body:not(.user-is-tabbing) .outline:focus {
  outline: 0px;
}

body:not(.user-is-tabbing) .btn.border-0:focus,
body:not(.user-is-tabbing) .btn.border-0:active {
  border: 0 !important;
}

body:not(.user-is-tabbing) .form-control:focus {
  background-image: none !important;
  border-color: var(--blue);
  color: rgb(0, 0, 0) !important;
}

.table td {
  vertical-align: middle;
}

.table th {
  border-top: 0 !important;
}

.table thead th {
  border-bottom: 1px solid #bdbdbd;
}

// Overide fontawesome icon styling
.svg-inline--fa {
  color: #828282;
}

a.disabled {
  pointer-events: none;
}
