.avatar {
  height: 37px;
  width: 37px;
  border-radius: 100%;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #54575a;
}
