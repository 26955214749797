.header {
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-bottom: 5px;
  font-weight: bold;
}

.section {
  margin-bottom: 16px;
}
