.container {
  &:before {
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 12px;
    display: inline-block;
    margin-right: 8px;
  }
}

.aligning:before {
  background: #ffb71b;
}

.implementing:before {
  background: #008575;
}

.expanding:before {
  background: #4197cb;
}

.testing:before {
  background: #fdafbf;
}

.evaluating:before {
  background: #f78c2a;
}
