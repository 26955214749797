.flatButton {
  padding: 1rem 3rem;
  font-size: 1.4rem;
}

.flatButton_disabled {
  background-color: #e0e0e0;
  color: #808080;
  cursor: not-allowed !important;

  &:hover {
    background-color: #e0e0e0;
  }
}
