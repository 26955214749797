.main {
  flex: 1 1;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 27px;
  background-color: #ffffff;
  overflow-y: auto;
  padding-top: 0;
}

.navAndContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mainExpanded {
  padding-left: 86px;
}