.container {
  position: relative;

  &Inline {
    display: inline-block;
  }
}

.dropdownButton {
  padding: 8px;
  background: #f5f5f5;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #54575a;
  line-height: 1.6rem;

  &Stretch {
    width: 100%;
    justify-content: space-between;
  }

  &:hover:enabled {
    background-color: rgba(196, 196, 196, 0.3);
  }
}

.dropdownIndicator {
  margin-left: 8px;

  &Open {
    transform: rotate(180deg);
  }
}

.buttonPositioner {
  display: flex;
  height: 100%;
  align-items: center;
}

.dropdownMenu {
  position: absolute;
  top: calc(100% + 8px);
  border-radius: 3px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border: solid 1px #dedede;
  background: #f5f5f5;
  display: none;
  z-index: 3;
  padding: 8px 3px;

  &Open {
    display: block;
  }

  &RightAlign {
    right: 0;
  }
}

.menuOption {
  padding: 8px 5px;
  white-space: nowrap;
  position: relative;
  line-height: 1.6rem;
  cursor: default;

  &:hover {
    background-color: #f2f2f2;
  }
}
