.nav {
  width: 225px;
  padding: 1.6rem;
  display: none;
  flex-direction: column;
  background: #fafafa;
  box-shadow: 0 6px 4px 0 rgba(0, 0, 0, 0.25);
  z-index: 40;
  height: 100%;

  &Expanded {
    display: flex;
  }

  &Collapsed {
    .headerText,
    :global(.nav-item__label) {
      display: none;
    }

    .headerIcon {
      margin-right: 0;
    }

    :global(.nav-item__navIcon) {
      margin-right: 0;
    }

    :global(.nav-item__navLink) {
      padding: 0;
      justify-content: center;
    }

    &:hover {
      .headerIcon {
        margin-right: 9px;
      }

      .headerText,
      :global(.nav-item__label) {
        display: inline;
      }

      :global(.nav-item__navLink) {
        padding: 8px;
        justify-content: flex-start;
      }

      :global(.nav-item__navIcon) {
        margin-right: 20px;
      }
    }
  }
}

.headerContainer {
  padding: 0 9px;
  margin-bottom: 23px;
  display: flex;
  align-items: center;
  height: 35px;
}

.headerIcon {
  margin-right: 9px;
  width: 35px;
}

.headerText {
  font-weight: 500;
  line-height: normal;
  flex: 1;
  font-size: 1.65rem;
}

.expandIconContainer {
  position: absolute;
  right: -12px;
  top: 84px;
  color: var(--blue);
  height: 23px;
  width: 23px;
  border-radius: 20px;
  border: 1px solid var(--blue);
  display: none;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
  font-size: 1.6rem;
}

.sideNavHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.collapseIcon {
  color: #54575a;
  background-color: #fafafa;
  margin-left: auto;
}

.logo {
  width: 140px;
}

.accordionToggle {
  &:hover {
    background-color: initial;
    color: #54575a;
  }
}

.nestedNavItem {
  padding-left: 20px;
}

.betaText {
  margin-left: 8px;
  color: var(--blue);
  font-weight: 600;
  margin-top: 2px;
}
