.button {
  border: none;
  background: white;
  border-radius: 3px;
  line-height: 0;
  padding: 6px;

  &:hover {
    background: #f2f2f2;
  }

  &:disabled:hover {
    background: initial;
  }
}

.disableHover {
  &:hover {
    background: #f5f5f5;
  }
}
