.hiddenInput {
  height: 0;
  width: 0;
  opacity: 0;

  &:checked {
    ~ .customCheckbox {
      border: solid 1px #008575;
      background-color: #008575;
    }
  }

  &:disabled {
    ~ .customCheckbox {
      background-color: #bbbbbb;
      border: solid 1px #b0b0b0;
    }
  }
}

.checkmark {
  font-size: 1rem;
  color: white;
}

.customCheckbox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  border-radius: 3px;
  border: solid 1px #b0b0b0;

  &WithLabel {
    margin-right: 8px;
  }
}

.label {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
