.navCollapsed {
  &:hover {
    .navLink {
      background: red;
    }
  }
}

:global(.nav-item__navLink) {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  height: 37px;
  white-space: nowrap;
  text-decoration: none !important;
  color: #54575a;
  cursor: pointer;
  font-size: 1.6rem;
  margin-bottom: 6px;

  &:hover {
    background-color: rgba(15, 186, 168, 0.1);
    color: #54575a;
  }
}

.selected {
  background-color: rgba(15, 186, 168, 0.1);
  color: #008575 !important;
  font-weight: 700;

  path {
    fill: #008575;
  }

  .navIconCollapsed {
    right: 1px;
  }
}

:global(.nav-item__navIcon) {
  width: 25px;
  text-align: center;
  margin-right: 8px;
}
