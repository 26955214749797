.button {
  border-radius: 50% !important;
  display: inline-flex !important;
  cursor: pointer !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: transparent !important;
  border: 0 white !important;
  width: 30px;
  height: 30px;
}

.button:hover {
  background-color: #f5f5f5 !important;
  box-shadow: none !important;
}

.disabled {
  opacity: 50%;
}

.disabled:hover {
  background-color: transparent !important;
}
