.pageNavBarLink {
  text-transform: uppercase;
  font-size: 1.6rem;
  letter-spacing: normal;
  color: #54575a;
  text-decoration: none;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 1.9;

  &:hover {
    color: #54575a;
    background-color: rgba(15, 186, 168, 0.1);
    font-weight: normal;
    text-decoration: none;
  }
}

.selected {
  color: #008575;
  border-bottom: 3px solid #008575;
  border-radius: 4px 4px 0 0;
  font-weight: 700;

  &:hover {
    color: #008575;
    font-weight: 700;
  }
}
