.dropdown {
  padding: 16px;
  position: absolute;
  margin-top: 3px;
}

.filterButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.multiSelectButton {
  height: 40px;
  width: 86px;
  font-size: 1.4rem;
}

.selectAllButton {
  padding-left: 0;
}

.check {
  font-weight: normal;
  font-size: 1.6rem;
  label {
    margin-left: 12px;
  }
  input {
    margin-top: 0.5rem;
  }
}

.secondaryButton {
  font-size: 1.4rem;
}

.searchIcon {
  position: absolute;
  cursor: pointer;
  font-size: 1.8rem;
  background: transparent;
  margin-top: 4px;
  right: 35px;
  color: #54575a;
  height: 13px;
  width: 13px;
}

.search {
  width: 238px;
}

.checkboxes {
  max-height: 150px;
  overflow-y: auto;
}
