.blueTheme {
  border: 0.15rem solid #53c8e7;
  background-color: rgba(83, 200, 231, 0.1);
}

.orangeTheme {
  border: 0.15rem solid #f19e1f;
  background-color: rgba(255, 225, 162, 0.5);
}

.magentaTheme {
  border: 0.15rem solid #cc7e9c;
  background-color: rgba(253, 175, 191, 0.1);
}

.purpleTheme {
  border: 0.15rem solid #c4a6ff;
  background-color: #f5f4ff;
}

.common {
  text-transform: capitalize;
  color: #54575a;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  line-height: normal;
}
