@import "styles";

.customToggle {
  background-color: transparent;
  border: 0;
  color: $green;
  font-size: 16px;
  font-weight: bold;
}

.navExpandBottomIcon {
  margin-left: 8px;
}

.dropdown {
  margin-right: 8px;
}

.dropdownItem {
  font-size: 1.5rem;
  padding: 0.75rem;
  text-align: center;
}
