.progressContainer {
  height: 10px;
  border-radius: 3px;
  background-color: rgba(255, 225, 162, 0.5);
}

.error {
  background-color: rgba(255, 224, 224, 0.5);

  .progressBar {
    background-color: #ff0000;
  }
}

.success {
  background-color: #8fd4ab;

  .progressBar {
    background-color: #8fd4ab;
  }
}

.progressBar {
  background-color: #febe2d;
  height: 100%;
  border-radius: inherit;
  transition: width 0.3s ease;
}
