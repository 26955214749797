.truncatedCell {
  width: 100%;
}

.headerColumnText {
  margin-right: 8px;
}

.shareButton {
  margin: 0 8px;
}

.topTableCard {
  margin-bottom: 40px;
}

.headlessTableCard {
  margin-bottom: 40px;
  thead {
    display: none;
  }
  table td {
    border-top: none;
  }
}
