@import "styles";

.header {
  border-bottom: none;

  &WithBorder {
    border-bottom: 0.5px solid #b0b0b0;
  }
}

.headerTexts {
  flex: 3 1;
}

.mainHeaderContents {
  display: flex;
}

.headerTitle {
  font-weight: 600;
}

.headerStatus {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.headerFirstRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &WithNav {
    padding-bottom: 2rem;
  }

  &WithBackLink {
    padding-bottom: 14px;
  }
}

.subheader {
  font-size: 1.6rem;
  margin-bottom: 0;
}

.backLink {
  font-size: 1.6rem;
  display: inline-block;
  margin-bottom: 1rem;

  &Icon {
    font-size: 1rem;
    margin-right: 5px;
  }
}

button.dropdown {
  background: $green;
  color: white;
  width: 162px;
  height: 45px;
  justify-content: center;
  font-weight: 500;

  &:hover {
    background: $green;
  }

  path {
    fill: white;
  }
}

.headerButton {
  padding: 0 27px;
}
