.truncatedCell {
  width: 100%;
}

.headerColumnText {
  margin-right: 8px;
}

.shareButton {
  margin: 0 8px;
}

.name {
  color: var(--blue);
}